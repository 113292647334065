import type { ButtonStyledProps } from '@components/atoms/button/types';
import styled from 'styled-components';

export const Button = styled.button<ButtonStyledProps>`
    display: ${(props) => (props.inline ? 'inline-flex' : 'flex')};
    justify-content: center;
    align-items: center;
    font-weight: 500;
    letter-spacing: 0.002em;
    padding: ${(props) => {
        if (props.variant === 'filter') {
            return '13px 24px';
        }
        if (props.variant === 'icon') {
            return '0';
        }
        switch (props.size) {
            case 's':
                return props.iconButton ? '0px' : '4px 8px';
            case 'm':
                return props.iconButton ? '4px' : '8px 16px';
            case 'xl':
                return props.iconButton ? '9px' : '13px 24px';
            case 'xxl':
                return props.iconButton ? '9px' : '13px 24px';
        }

        return '16px 8px';
    }};

    ${(props) => {
        if (props.variant === 'filter') {
            return {
                [props.theme.breakpointTo.md]: {
                    padding: '10px',
                },
            };
        }
        if (props.variant === 'icon') {
            return {
                height: '22px',
                width: '22px',
                '.button-text': {
                    height: '22px',
                },
            };
        }

        return undefined;
    }}

    width: ${(props) => (props.$fullWidth ? '100%' : 'auto')};
    min-height: ${(props) => {
        if (props.variant === 'filter') {
            return '44px';
        }

        switch (props.size) {
            case 's':
                return '24px';
            case 'm':
                return '32px';
            case 'xl':
                return '44px';
            case 'xxl':
                return '60px';
        }

        return '32px';
    }};
    text-decoration: none;
    transition: all 0.3s;
    background: ${(props) => {
        switch (props.variant) {
            case 'primary':
                return props.theme.palette.primaryA1;
            case 'tertiary':
                return props.theme.palette.primaryA6;
            case 'error':
                return props.theme.palette.secondaryA4;
            case 'secondary':
            case 'error-secondary':
            case 'ghost':
                return 'transparent';
        }

        return 'transparent';
    }};
    border: ${(props) => {
        switch (props.variant) {
            case 'primary':
                return `1px solid ${props.theme.palette.primaryA1}`;
            case 'secondary':
                return `1px solid ${props.theme.palette.primaryA1}`;
            case 'tertiary':
                return `1px solid ${props.theme.palette.primaryA1}`;
            case 'filter':
                return `2px solid ${
                    props.theme?.components?.button?.variant?.filter?.borderColor ||
                    props.theme.palette.primaryA6
                }`;
            case 'error-secondary':
                return `1px solid ${props.theme.palette.secondaryA4}`;
        }

        return 'transparent';
    }};

    border-radius: ${(props) => {
        if (props.variant === 'filter') {
            return '12px';
        }
        switch (props.size) {
            case 'xxl':
                return '16px';
            case 'xl':
                return '12px';
            case 'm':
                return '8px';
            case 's':
                return '6px';
        }

        return '8px';
    }};

    ${(props) => {
        switch (props.size) {
            case 'xxl':
                return props.theme.typography.bigTextBold;
            case 'xl':
                return props.theme.typography.button;
            case 'm':
            case 's':
                return props.theme.typography.smallTextBold;
        }

        return props.theme.typography.smallTextBold;
    }}

    margin: 0;
    color: ${(props) => {
        switch (props.variant) {
            case 'primary':
                return 'white';
            case 'ghost':
            case 'secondary':
                return props.theme.palette.primaryA1;
            case 'filter':
                return (
                    props.theme?.components?.button?.variant?.filter?.color ||
                    props.theme.palette.textLight
                );
            case 'tertiary':
                return props.theme.palette.primaryA1;
            case 'error-secondary':
                return props.theme.palette.secondaryA4;
            case 'error':
                return 'white';
            case 'icon':
                return props.theme.palette.primaryA1;
        }

        return 'transparent';
    }};
    pointer-events: ${(props) => {
        if (props.disabled || props.loading) {
            return 'none';
        }

        return 'auto';
    }};
    cursor: ${(props) => {
        if (props.disabled || props.loading) {
            return 'none';
        }

        return 'pointer';
    }};

    &:hover {
        ${(props) => props.theme.breakpointFrom.sm} {
            ${(props) => {
                switch (props.variant) {
                    case 'primary':
                    case 'secondary':
                        return {
                            color: 'white',
                            backgroundColor: props.theme.palette.primaryA2,
                            borderColor: props.theme.palette.primaryA2,
                            textDecoration: 'none',
                        };
                    case 'error':
                    case 'tertiary':
                    case 'icon':
                        return {
                            opacity: 0.5,
                            textDecoration: 'none',
                        };
                    case 'filter':
                        return {
                            color: props.theme.palette.primaryA1,
                            border: `2px solid ${props.theme.palette.primaryA1}`,
                        };
                    case 'ghost':
                        return {
                            color: props.theme.palette.textMain,
                            textDecoration: 'none',
                        };
                    case 'error-secondary':
                        return {
                            color: props.theme.palette.primaryA7,
                            textDecoration: 'none',
                            backgroundColor: props.theme.palette.secondaryA4,
                        };
                }

                return undefined;
            }};
        }
    }

    &:active,
    &.active {
        ${(props) => props.theme.breakpointFrom.sm} {
            ${(props) => {
                switch (props.variant) {
                    case 'ghost':
                        return {
                            color: props.theme.palette.textLight,
                        };
                    case 'filter':
                        return {
                            border: `2px solid ${props.theme.palette.primaryA1}`,
                            color: props.theme.palette.primaryA1,
                            'svg path': {
                                fill: props.theme.palette.primaryA1,
                            },
                        };
                }

                return undefined;
            }};
        }

        ${(props) => props.theme.breakpointTo.sm} {
            opacity: 0.5;
        }
    }

    &:disabled {
        ${(props) => {
            switch (props.variant) {
                case 'primary':
                    return {
                        color: 'white',
                        backgroundColor: props.theme.palette.primaryA3,
                        borderColor: props.theme.palette.primaryA3,
                    };
                case 'secondary':
                    return {
                        color: props.theme.palette.primaryA3,
                        borderColor: props.theme.palette.primaryA3,
                    };
                case 'ghost':
                    return {
                        color: props.theme.palette.primaryA3,
                    };
                case 'icon':
                    return {
                        color: props.theme.palette.primaryA3,
                        borderColor: props.theme.palette.primaryA3,
                    };
            }

            return undefined;
        }};
    }

    &:focus {
        ${(props) => {
            switch (props.variant) {
                case 'primary':
                case 'secondary':
                    return {
                        color: 'white',
                        backgroundColor: props.theme.palette.primaryA2,
                        borderColor: props.theme.palette.primaryA2,
                        textDecoration: 'none',
                    };
                case 'error':
                case 'tertiary':
                    return {
                        opacity: 0.5,
                        textDecoration: 'none',
                    };
                case 'filter':
                    return {
                        color: props.theme.palette.primaryA1,
                        border: `2px solid ${props.theme.palette.primaryA1}`,
                    };
                case 'ghost':
                    return {
                        color: props.theme.palette.textMain,
                        textDecoration: 'none',
                    };
                case 'error-secondary':
                    return {
                        color: props.theme.palette.primaryA7,
                        textDecoration: 'none',
                        backgroundColor: props.theme.palette.secondaryA4,
                    };
            }

            return undefined;
        }};
        text-decoration: none;
    }

    .button-left-addon {
        display: flex;
    }

    .button-left-addon + .button-text {
        margin-left: 8px;
    }

    .button-text + .button-right-addon {
        margin-left: 8px;
    }

    .hidden-button-elem {
        visibility: hidden;
    }

    .button-inner-container {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        font-family: inherit;
    }

    .button-spinner {
        position: absolute;
    }

    .button-text {
        line-height: 1;
        font-family: inherit;
        // remove unnecessary pixels when use svg in buttons
        svg {
            display: inline-block;
            vertical-align: top;
        }
    }
`;

export const ButtonText = styled.button`
    color: ${(props) => props.theme.palette.primaryA1};
    ${(props) => props.theme.typography.button}
    cursor: pointer;
    transition: all 0.3s;
    background: none;

    &:hover {
        text-decoration: underline;
        opacity: 0.5;
    }
`;
