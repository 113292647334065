export const PROFILE_ID = 'profileId';
export const PROFILE_CONFIGURATION = 'profileConfiguration';

export const FORM_MAX_LENGTH = {
    DEFAULT: 255,
    PHONE_NUMBER: 14,
    // TODO maxlength default for message - 1024
    MESSAGE: 850,
};

export const FUZZINESS_LEVEL_DEFAULT = 100;
