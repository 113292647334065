import type { DomainSettingsModel } from '@modules/shared/models/white-label/domain-settings-model';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export type DomainState = {
    settings: DomainSettingsModel;
    isInitialized: boolean;
    isLoading: boolean;
};

export const defaultSettings: DomainSettingsModel = {
    organization_name: '',
    logo_settings: {
        logo_url: undefined,
        logo_wide_url: undefined,
        favicon_url: undefined,
    },
    login_settings: {
        google: {
            client_id: '',
            enabled: false,
        },
        sign_up_enabled: false,
    },
    docver_settings: {
        tg_link_enabled: false,
        tg_link_base_url: '',
    },
    other_settings: {
        default_page_title: '',
        show_contact_us: false,
        privacy_policy_url: '',
        terms_of_service_url: '',
        service_email: '',
        privacy_organization_name: '',
        doc_links: {
            api: undefined,
            mobile_sdk: undefined,
            widget: undefined,
            bot: undefined,
            webhooks: undefined,
        },
    },
    domain_settings: {
        api: undefined,
        sdk: undefined,
        dash: undefined,
    },
    billing_settings: {
        enabled: true,
    },
    is_admin_panel: false,
    ui_settings: undefined,
    is_ds: false,
};

const initialState: DomainState = {
    settings: defaultSettings,
    isInitialized: false,
    isLoading: false,
};

export const domainSlice = createSlice({
    name: 'domainSlice',
    initialState,
    reducers: {
        clearSettings: (state) => {
            state.settings = defaultSettings;
        },
        setSettings: (state, { payload }: PayloadAction<DomainSettingsModel>) => {
            state.settings = payload;
        },
        setInitialized: (state, { payload }: PayloadAction<boolean>) => {
            state.isInitialized = payload;
        },
        setLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isLoading = payload;
        },
    },
});
